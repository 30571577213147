import type { ArtistWithProducts } from '../types'
import type { Customer, Product } from '../generated/graphql'

export function toArtistWithProducts(
  artist: Customer | null,
  products: Product[]
): ArtistWithProducts | null {
  // If the artist is not existing, then she also can not have
  // any products and therefore we return null
  if (!artist) {
    return null
  }
  return { artist, products }
}
